/* eslint-disable @typescript-eslint/no-base-to-string */
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';
import Video from '../components/Video';
import sessionizeFrame from "./sessionizeFrame";

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    margin-right: 24px;
    margin-left: 24px;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const iframeWrapperCss = css`
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjust this value to your needs */
`;
const iframeCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  /* For WebKit-based browsers (Chrome, Safari, etc.) */
  ::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: darkgrey transparent;
  }
`;
function Speaking() {
  const sessionizeContainerRef: React.MutableRefObject<undefined> = useRef(undefined);
  sessionizeFrame(sessionizeContainerRef);

  return (
    <IndexLayout>
      <Helmet>
        <title>Speaking</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false}/>
            </div>
          </div>
        </header>
        <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
          <div css={inner}>
            <article className={`${PostFull} post page ${NoImage}`}>
              <PostFullHeader>
                <PostFullTitle>Speaking</PostFullTitle>
              </PostFullHeader>
              <PostFullContent className="post-full-content">
                <div className="post-content">
                  <p>
                      I like speaking at conferences and at my workplace!
                    <br/>
                      Covering topics ranging from Kotlin and Kotlin Multiplatform, benchmarking and
                      more. These are some of my talks.
                    <h2> 2023 </h2>
                    <Video
                      videoSrcURL="https://player.vimeo.com/video/844413744?autopause=0&autoplay=0&color=00adef&portrait=0&byline=0&title=0"
                      videoTitle="Crash Course in Building Your First Gradle Plugin - Droidcon San Francisco 2023"
                    />
                    <Video
                      videoSrcURL="https://player.vimeo.com/video/845904539?autopause=0&autoplay=0&color=00adef&portrait=0&byline=0&title=0"
                      videoTitle="Crash Course in Building Your First Gradle Plugin - Droidcon Berlin 2023"
                    />
                    <h2> 2022 </h2>
                    <Video
                      videoSrcURL="https://player.vimeo.com/video/753790427?autopause=0&autoplay=0&color=00adef&portrait=0&byline=0&title=0"
                      videoTitle="Benchmarking and other stories - Droidcon NYC - Sept 2022"
                    />
                    <Video
                      videoSrcURL="//player.vimeo.com/video/751663227?autopause=0&autoplay=0&color=00adef&portrait=0&byline=0&title=0"
                      videoTitle="Droid-Conversations with Madona & Matt - Interview with Iury Souza"
                    />
                    <Video
                      videoSrcURL="//player.vimeo.com/video/734760050?autopause=0&amp;autoplay=0&amp;color=00adef&amp;portrait=0&amp;byline=0&amp;title=0"
                      videoTitle="Benchmarking and other stories - Droidcon Berlin - July 2022"
                    />
                    <Video
                      videoSrcURL="//player.vimeo.com/video/723376674?autopause=0&amp;autoplay=0&amp;color=00adef&amp;portrait=0&amp;byline=0&amp;title=0"
                      videoTitle="Benchmarking and other stories - Droidcon Berlin - May 2022"
                    />
                    <Video
                      videoSrcURL="https://www.youtube.com/embed/zHoJEDjLtAY"
                      videoTitle="Benchmarking and other stories - AndroidMakers Paris - April 2022"
                    />
                    <h2> 2021 </h2>
                    <Video
                      videoSrcURL="https://drive.google.com/file/d/12iheQ3eTBrg3vYbEGWs7zRKJEkHv7Rs9/preview"
                      videoTitle="Crash Course Kotlin Multiplatform - Company event - August 2021"
                    />
                  </p>
                  <div className="iframe-wrapper" style={iframeWrapperCss}>
                    <div ref={sessionizeContainerRef} className="wrapper" style={iframeCss} />
                  </div>
                </div>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer/>
      </Wrapper>
    </IndexLayout>
  );
}

export default Speaking;
